

























import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';

@Component({
  components: { IllustrationComponent },
})
export default class Error extends Vue {
    private IllustrationType = IllustrationType;
}
